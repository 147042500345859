import { useUI } from '../context/UIContext';

const ChartHeader = () => {
  const {
    activeButton,
    setActiveButton,
    handleShowModal,
    showSelectFutureModal,
    showCompareFuturesModal,
    showSavedChartsModal,
    isFullScreen,
    toggleFullScreen,
  } = useUI();

  return (
    <div className="chart-header hex-container highcharts-stocktools-wrapper highcharts-bindings-container highcharts-bindings-wrapper">
      <div className="hex-row row-1">
        <div
          className={`chart-btn symbol-btn ${showSelectFutureModal === true ? 'active' : ''}`}
          title="Seleccionar futuro"
          onClick={() => handleShowModal('SelectFutureModal')}
        >
          <img src="/icons/symbol.png" className="button-icon" alt="Seleccionar Futuro" />
          <span>RFX20/OCT24</span>
        </div>

        <div className="chart-btn interval-btn" title="Seleccionar intervalo">
          <img src="/icons/interval.png" className="button-icon" alt="Seleccionar Intervalo" />
          <span>5 minutos</span>
        </div>

        <div
          className={`chart-btn compare-btn ${showCompareFuturesModal === true ? 'active' : ''}`}
          title="Comparar futuros"
          onClick={() => handleShowModal('CompareFuturesModal')}
        >
          <img src="/icons/compare.png" className="button-icon" alt="Comparar Futuros" />
          <span>Comparar</span>
        </div>

        <div
          className={`chart-btn indicators-btn highcharts-indicators ${activeButton === 'indicators-btn' ? 'active' : ''}`}
          title="Indicators"
          onClick={() => {
            if (activeButton === 'indicators-btn') {
              setActiveButton(null);
              const popup = document.querySelector('.highcharts-popup');
              if (popup) {
                popup.style.display = 'none';
              }
            } else {
              setActiveButton('indicators-btn');
              const popup = document.querySelector('.highcharts-popup-close');
              if (popup && !popup.__isEventSet) {
                popup.addEventListener('click', () => {
                  setActiveButton(null);
                  const popup = document.querySelector('.highcharts-popup');
                  if (popup) {
                    popup.style.display = 'none';
                  }
                });
                popup.__isEventSet = true;
              }
            }
          }}
        >
          <img src="https://code.highcharts.com/gfx/stock-icons/indicators.svg" className="button-icon"/>
          <span>Indicadores</span>
        </div>

        <div
          className="chart-btn colors-btn"
          title="Personalizar colores del gráfico"
          onClick={() => handleShowModal('ChangeColorsModal')}
        >
          <img src="/icons/colors.png" className="button-icon" alt="Personalizar Colores" />
          <span>Colores</span>
        </div>

        <div
          className={`chart-btn saved-charts-btn ${showSavedChartsModal === true ? 'active' : ''}`}
          title="Gráficos Guardados"
          onClick={() => handleShowModal('SavedChartsModal')}
        >
          <img src="/icons/saved-charts.png" className="button-icon" alt="Gráficos Guardados" />
          <span>Tus graficos</span>
        </div>

        <div 
          className="chart-btn save-chart-btn highcharts-save-chart"
          title="Guardar Gráfico"
        />
        <div
          className={`chart-btn full-screen-btn ${isFullScreen === true ? 'active' : ''}`}
          title="Pantalla Completa"
          onClick={toggleFullScreen}
        />
      </div>
    </div>
  );
};

export default ChartHeader;