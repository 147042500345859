import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const Login = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const { loginUser, registerUser, alertMessage } = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isLogin) {
            loginUser(email, password);
        } else {
            if (password !== confirmPassword) {
                alert('Passwords do not match');
                return;
            }
            registerUser(name, lastname, email, password);
        }
    };

    const handleSwitch = () => {
        setIsLogin(!isLogin);
        clearFields();
    };

    const clearFields = () => {
        setName('');
        setLastname('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="login-container">
            <div className={`login-card ${isLogin ? '' : 'signup-mode'}`}>
                {isLogin ? (
                    <LoginContent 
                        email={email} 
                        setEmail={setEmail} 
                        password={password} 
                        setPassword={setPassword} 
                        showPassword={showPassword} 
                        togglePasswordVisibility={togglePasswordVisibility} 
                        alertMessage={alertMessage} 
                        handleSubmit={handleSubmit} 
                        handleSwitch={handleSwitch} 
                    />
                ) : (
                    <SignupContent 
                        name={name} 
                        setName={setName} 
                        lastname={lastname} 
                        setLastname={setLastname} 
                        email={email} 
                        setEmail={setEmail} 
                        password={password} 
                        setPassword={setPassword} 
                        confirmPassword={confirmPassword} 
                        setConfirmPassword={setConfirmPassword} 
                        showPassword={showPassword} 
                        togglePasswordVisibility={togglePasswordVisibility} 
                        alertMessage={alertMessage} 
                        handleSubmit={handleSubmit} 
                        handleSwitch={handleSwitch} 
                    />
                )}
            </div>
        </div>
    );
};

const LoginContent = ({ email, setEmail, password, setPassword, showPassword, togglePasswordVisibility, alertMessage, handleSubmit, handleSwitch }) => (
    <div className="card-content login-content">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
            <InputField type="email" placeholder="Email" value={email} onChange={setEmail} autoComplete="email" />
            <PasswordField 
                placeholder="Password" 
                value={password} 
                onChange={setPassword} 
                showPassword={showPassword} 
                togglePasswordVisibility={togglePasswordVisibility} 
                autoComplete="current-password" 
            />
            {alertMessage && <p className="text-danger">{alertMessage}</p>}
            <button type="submit" className="btn btn-primary w-100">Login</button>
        </form>
        <div className="mt-3">
            <span>Don't have an account?</span>
            <button onClick={handleSwitch} className="btn btn-link">Register</button>
        </div>
    </div>
);

const SignupContent = ({ name, setName, lastname, setLastname, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, showPassword, togglePasswordVisibility, alertMessage, handleSubmit, handleSwitch }) => (
    <div className="card-content signup-content">
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
            <InputField type="text" placeholder="Name" value={name} onChange={setName} autoComplete="given-name" />
            <InputField type="text" placeholder="Lastname" value={lastname} onChange={setLastname} autoComplete="family-name" />
            <InputField type="email" placeholder="Email" value={email} onChange={setEmail} autoComplete="email" />
            <PasswordField 
                placeholder="Password" 
                value={password} 
                onChange={setPassword} 
                showPassword={showPassword} 
                togglePasswordVisibility={togglePasswordVisibility} 
                autoComplete="new-password" 
            />
            <PasswordField 
                placeholder="Confirm Password" 
                value={confirmPassword} 
                onChange={setConfirmPassword} 
                showPassword={showPassword} 
                togglePasswordVisibility={togglePasswordVisibility} 
                autoComplete="new-password" 
            />
            {alertMessage && <p className="text-danger">{alertMessage}</p>}
            <button type="submit" className="btn btn-primary w-100">Register</button>
        </form>
        <div className="mt-3">
            <span>Already have an account?</span>
            <button onClick={handleSwitch} className="btn btn-link">Login</button>
        </div>
    </div>
);

const InputField = ({ type, placeholder, value, onChange, autoComplete }) => (
    <div className="mb-3">
        <input
            type={type}
            className="form-control"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            required
            autoComplete={autoComplete}
        />
    </div>
);

const PasswordField = ({ placeholder, value, onChange, showPassword, togglePasswordVisibility, autoComplete }) => (
    <div className="mb-3 password-container">
        <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            required
            autoComplete={autoComplete}
        />
        <span className="password-toggle" onClick={togglePasswordVisibility}>
            {showPassword ? <EyeSlash /> : <Eye />}
        </span>
    </div>
);

export default Login;