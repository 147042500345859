import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useUI } from '../context/UIContext';
import { changeColors, getChartInstance } from '../../utils/chartUtils'; // Importamos la función de actualización
import './modals.css';

const ChangeColorsModal = () => {
  const { handleCloseModal } = useUI();
  const [chartOptions, setChartOptions] = useState(null);

  // Obtener chartOptions solo una vez cuando el componente se monta
  useEffect(() => {
    const options = getChartInstance().options;
    setChartOptions(options);
    console.log('chartOptions', options); // Este log solo se ejecuta una vez
  }, []);

  const extractCssVariable = (value) => {

    const match = value.match(/var\((--[^)]+)\)/);
    if (match) {
      return match[1]; // Devuelve solo el nombre de la variable CSS
    }
    return value; // Si no es una variable CSS, devuelve el valor original
  };

  const getValidColorValue = (value) => {

    if (typeof value !== 'string') {
      return value; // Si no es una cadena, devuelve el valor tal como está
    }

    const cssVariable = extractCssVariable(value);
    if (cssVariable.startsWith('--')) {
      return getComputedStyle(document.body).getPropertyValue(cssVariable).trim();
    }
    return cssVariable; // Si ya es un valor hexadecimal, lo retorna tal cual
  };

  const getValidHexColorValue = (value) => {
    // Verifica si el valor es un objeto de gradiente
    if (value?.stops) {
      // Si es un gradiente, devolver el primer color en stops
      return value.stops[0]?.[1] || '#000000'; // Retorna un color por defecto si no se encuentra
    }
    
    // Si el valor es una cadena que comienza con # (valor hexadecimal), lo retorna
    if (typeof value === 'string' && value.startsWith('#')) {
      return value;
    }
  
    // Si llega aquí, devuelve un valor por defecto
    return '#000000';
  };

  // Definir los estados con valores iniciales
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [upColor, setUpColor] = useState('#000000');
  const [downColor, setDownColor] = useState('#000000');
  const [priceColor, setPriceColor] = useState('#000000');
  const [linesColor, setLinesColor] = useState('#000000');
  const [timeColor, setTimeColor] = useState('#000000');
  const [volumeColor, setVolumeColor] = useState('#000000');

  // Efecto para actualizar los colores cuando `chartOptions` esté disponible
  useEffect(() => {
    if (chartOptions) {
      setBackgroundColor(getValidColorValue(chartOptions.chart.backgroundColor));
      setUpColor(getValidColorValue(chartOptions.series[0]?.upColor));
      setDownColor(getValidColorValue(chartOptions.series[0]?.color));
      setPriceColor(getValidColorValue(chartOptions.yAxis[0]?.labels?.style?.color));
      setLinesColor(getValidColorValue(chartOptions.yAxis[0].gridLineColor));
      setTimeColor(getValidColorValue(chartOptions.xAxis[0]?.labels?.style?.color));
      setVolumeColor(getValidColorValue(getValidHexColorValue(chartOptions.series[1]?.color)));
    }
  }, [chartOptions]);

  // Función para guardar y actualizar el gráfico
  const handleSave = () => {
    changeColors({
      backgroundColor,
      upColor,
      downColor,
      priceColor,
      linesColor,
      timeColor,
      volumeColor
    });
    handleCloseModal('ChangeColorsModal');
  };

  return (
    <Modal show={true} onHide={() => handleCloseModal('ChangeColorsModal')}
    dialogClassName="modal-dialog-centered">
      <Modal.Header closeButton>
        <Modal.Title>Personalizar colores del gráfico</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>Background</label>
          <input
            type="color"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Vela alcista</label>
          <input
            type="color"
            value={upColor}
            onChange={(e) => setUpColor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Vela bajista</label>
          <input
            type="color"
            value={downColor}
            onChange={(e) => setDownColor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Valores</label>
          <input
            type="color"
            value={priceColor}
            onChange={(e) => setPriceColor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Líneas horizontales</label>
          <input
            type="color"
            value={linesColor}
            onChange={(e) => setLinesColor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Fecha</label>
          <input
            type="color"
            value={timeColor}
            onChange={(e) => setTimeColor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Volumen</label>
          <input
            type="color"
            value={volumeColor}
            onChange={(e) => setVolumeColor(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button variant="primary" onClick={handleSave}>
          Guardar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeColorsModal;