import React, { createContext, useContext, useState } from 'react';

const UIContext = createContext();

export const UIProvider = ({ children }) => {
  const [showSelectFutureModal, setShowSelectFutureModal] = useState(false);
  const [showCompareFuturesModal, setShowCompareFuturesModal] = useState(false);
  const [showSavedChartsModal, setShowSavedChartsModal] = useState(false);
  const [showChangeColorsModal, setShowChangeColorsModal] = useState(false);

  const handleShowModal = (modalName) => {
    if (modalName === 'SelectFutureModal') {
      setShowSelectFutureModal(true);
    }
    if (modalName === 'CompareFuturesModal') {
      setShowCompareFuturesModal(true);
    }
    if (modalName === 'SavedChartsModal') {
      setShowSavedChartsModal(true);
    }
    if (modalName === 'ChangeColorsModal') {
      setShowChangeColorsModal(true);
    }
  };

  const handleCloseModal = (modalName) => {
    if (modalName === 'SelectFutureModal') {
      setShowSelectFutureModal(false);
    };
    if (modalName === 'CompareFuturesModal') {
      setShowCompareFuturesModal(false);
    };
    if (modalName === 'SavedChartsModal') {
      setShowSavedChartsModal(false);
    };
    if (modalName === 'ChangeColorsModal') {
      setShowChangeColorsModal(false);
    };
  };

  const [activeButton, setActiveButton] = useState(null);

  const [showTools, setShowTools] = useState(true);

  const [showTables, setShowTables] = useState(true);

  const toggleTables = () => {
    setShowTables(prevState => !prevState);
  };

  const [currentPrice, setCurrentPrice] = useState(false);

  const toggleCurrentPrice = () => {
    setCurrentPrice(prevState => !prevState);
  };

  const [showAnnotations, setShowAnnotations] = useState(false);

  const toggleAnnotations = () => {
    setShowAnnotations(prevState => !prevState);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(prevState => !prevState);
  };
  
  const [mainSeriesType, setMainSeriesType] = useState(false);

  return (
    <UIContext.Provider
      value={{
        showTools,
        setShowTools,
        showTables,
        toggleTables,
        showSelectFutureModal,
        showCompareFuturesModal,
        showChangeColorsModal,
        showSavedChartsModal,
        handleShowModal,
        handleCloseModal,
        activeButton,
        setActiveButton,
        currentPrice,
        toggleCurrentPrice,
        showAnnotations,
        toggleAnnotations,
        isFullScreen,
        toggleFullScreen,
        mainSeriesType,
        setMainSeriesType
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => useContext(UIContext);