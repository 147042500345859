import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const FLY_URL = process.env.REACT_APP_FLY_URL;
  console.log('FLY_URL', FLY_URL);
  
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.clear();
    setToken(null);
    setUser(null);
    setAlertMessage('');
    navigate('/login');
  }, [navigate]);

  const checkTokenExpiration = useCallback(() => {
    if (!token) return handleLogout();

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const isExpired = Date.now() >= decodedToken.exp * 1000;

    if (isExpired) {
      setAlertMessage('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
      setTimeout(handleLogout, 5000);
    }
  }, [token, handleLogout]);

  useEffect(() => {
    const interval = setInterval(checkTokenExpiration, 5 * 60 * 1000); // Cada 5 minutos
    return () => clearInterval(interval);
  }, [checkTokenExpiration]);

  useEffect(() => {
    if (!token) handleLogout();
  }, [token, handleLogout]);

  const loginUser = async (email, password) => {
    try {
      const response = await axios.post(
        `${FLY_URL}api/login`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const { token, user } = response.data;
      console.log(token, user, response.data);
      setToken(token);
      setUser(user);
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      setAlertMessage('Inicio de sesión exitoso');
      navigate('/');
    } catch (error) {
      console.error('Error en la autenticación:', error);
      setAlertMessage(error.response?.data?.message || 'Error en la autenticación');
    }
  };

  const registerUser = async (name, lastname, email, password) => {
    try {
      const response = await axios.post(
        `${FLY_URL}api/register`,
        { name, lastname, email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setAlertMessage('Usuario registrado correctamente. Ahora puede iniciar sesión.', response.data.message);
    } catch (error) {
      console.error('Error en el registro:', error);
      setAlertMessage(error.response?.data?.message || 'Error en el registro');
    }
  };  

  return (
    <AuthContext.Provider value={{
      token,
      user,
      alertMessage,
      setAlertMessage,
      handleLogout,
      loginUser,
      registerUser,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);