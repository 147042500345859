import { useUI } from '../context/UIContext';
import TablaIndices from './TablaIndices';
import TablaFuturos from './TablaFuturos';
import { getValue } from '../../utils/utils';
import { useData } from '../context/DataContext';
import './tablas.css';

const Tablas = () => {
  const { indexData, futureData, todayData } = useData();
  const { showTables, isFullScreen } = useUI();

  if (isFullScreen) return null;

  return (
    <div className={`tables-container ${showTables ? '' : 'hidden'}`}>
      <TablaFuturos futureData={Array.isArray(futureData) ? futureData : []} 
        getValue={getValue} todayData={Array.isArray(todayData) ? todayData : []} 
      />
    </div>
  );
};

export default Tablas;