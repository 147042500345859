import Highcharts from 'highcharts/highstock';

let chartInstance = null;
let selectedIndex = 'I.RFX20';
let selectedFuture = null;
let currentInterval = 300000;
let intervalLabel = '5 minutos'; 

// Funciones para manejar el estado
export const setChartInstance = (instance) => {
  chartInstance = instance;
};

export const getChartInstance = () => {
  return chartInstance;
};

export const setSelectedIndex = (value) => {
  selectedIndex = value;
};

export const getSelectedIndex = () => {
  return selectedIndex;
};

export const setInterval = (interval) => {
  currentInterval = interval;
};

export const getInterval = () => {
  return currentInterval;
};

export const setIntervalLabel = (label) => {
  intervalLabel = label;
};

export const getIntervalLabel = () => {
  return intervalLabel;
};

export const setSelectedFuture = (future) => {
  selectedFuture = future;
};

export const getSelectedFuture = () => {
  return selectedFuture;
};
 
let showTables = true; // Estado inicial para mostrar las tablas

// Función para alternar la visibilidad de las tablas
export const toggleShowTables = () => {
  console.log('showTables', showTables);
  
  showTables = !showTables;
};

// Función para obtener el estado actual de la visibilidad de las tablas
export const getShowTables = () => {
  return showTables;
};

export const changeColors = (
  {
    backgroundColor,
    upColor,
    downColor,
    priceColor,
    linesColor,
    timeColor,
    volumeColor
  }
) => {

  console.log('changeColors', backgroundColor, upColor, downColor, priceColor, linesColor, timeColor, volumeColor);
  
  if (chartInstance) {
    chartInstance.update({
      chart: {
        backgroundColor: backgroundColor,
      },
      xAxis: {
        labels: {
          style: {
            color: timeColor,
          },
        }
      },
      yAxis: [
        {
          labels: {
            style: {
              color: priceColor,
            },
            formatter: function () {
              return Highcharts.numberFormat(this.value, 0, '.', ',');
            }
          },
          gridLineColor: linesColor
        },
        {
          labels: {
            style: {
              color: priceColor,
            },
          },
          gridLineColor: linesColor
        },
      ],
      series: [
        {
          id: 'price',
          color: downColor,
          lineColor: downColor,
          upColor: upColor,
          upLineColor: upColor,
        },
        {
          id: 'volume',
          color: volumeColor
        }
      ]
    }, false);

    chartInstance.redraw();
  }
};