import { useState, useEffect, useRef } from 'react';
import { useUI } from '../context/UIContext';
import './chartTools.css';

const ChartTools = () => {
  const {
    activeButton,
    setActiveButton,
    currentPrice,
    toggleCurrentPrice,
    showAnnotations,
    toggleAnnotations,
    showTools
  } = useUI();

  // Estado para gestionar el submenú seleccionado y el botón activo
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);

  // Funciones para manejar el menú y los elementos
  const toggleMenu = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu(null);
    } else {
      setActiveMenu(menu);
    }
  };

  const handleItemClick = (menu, button) => {
    setActiveButton(button);
    setActiveMenu(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.classList.contains('close-menu')) {
        setActiveMenu(null); // Cierra el submenú.
      }
    };
  
    // Agregar listener de clic al documento.
    document.addEventListener('mousedown', handleClickOutside);
  
    // Limpieza al desmontar el componente.
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeMenu]);

  const SubMenu = ({ menu, items }) => (
    <div className="sub-menu horizontal" ref={menuRef}>
      {items.map((item) => (
        <div
          key={item.title}
          className={`hex-item ${item.className}`}
          title={item.title}
          onClick={() => handleItemClick(menu, item.button)}
        />
      ))}
    </div>
  );

  const getMenuContent = (menu, items) => {
    // Determinar si el botón activo pertenece al submenú actual
    const isSubmenuButtonActive = items.some(item => item.button === activeButton);

    return (
      <div
        className={`hex-item ${activeMenu === menu
            ? 'close-menu'
            : isSubmenuButtonActive
              ? 'active ' + activeButton // Esto asegura que se muestre el icono correspondiente al botón activo
              : items[0].button
          }`}
        title={activeMenu === menu ? 'Close' : items[0].title}
        onClick={() => toggleMenu(menu)}
      >
        {activeMenu === menu ? '' : null}
      </div>
    );
  };

  const menuItems = {
    annotations: [
      { title: 'Label', className: 'highcharts-label-annotation label-btn', button: 'label-btn' },
      { title: 'Ellipse', className: 'highcharts-ellipse-annotation ellipse-btn', button: 'ellipse-btn' },
      { title: 'Circle', className: 'highcharts-circle-annotation circle-btn', button: 'circle-btn' },
      { title: 'Rectangle', className: 'highcharts-rectangle-annotation rectangle-btn', button: 'rectangle-btn' },
    ],
    lines: [
      { title: 'Segment', className: 'highcharts-segment segment-btn', button: 'segment-btn' },
      { title: 'Arrow Segment', className: 'highcharts-arrow-segment arrow-segment-btn', button: 'arrow-segment-btn' },
      { title: 'Ray', className: 'highcharts-ray ray-btn', button: 'ray-btn' },
      { title: 'Arrow Ray', className: 'highcharts-arrow-ray arrow-ray-btn', button: 'arrow-ray-btn' },
    ],
    trendLines: [
      { title: 'Line', className: 'highcharts-infinity-line infinity-line-btn', button: 'infinity-line-btn' },
      { title: 'Arrow Line', className: 'highcharts-arrow-infinity-line arrow-infinity-line-btn', button: 'arrow-infinity-line-btn' },
      { title: 'Horizontal Line', className: 'highcharts-horizontal-line horizontal-line-btn', button: 'horizontal-line-btn' },
      { title: 'Vertical Line', className: 'highcharts-vertical-line vertical-line-btn', button: 'vertical-line-btn' },
    ],
    elliottWaves: [
      { title: 'Elliott 3', className: 'highcharts-elliott3 elliott3-btn', button: 'elliott3-btn' },
      { title: 'Elliott 5', className: 'highcharts-elliott5 elliott5-btn', button: 'elliott5-btn' },
      { title: 'Crooked 3', className: 'highcharts-crooked3 crooked3-btn', button: 'crooked3-btn' },
      { title: 'Crooked 5', className: 'highcharts-crooked5 crooked5-btn', button: 'crooked5-btn' },
    ],
    measures: [
      { title: 'Measure XY', className: 'highcharts-measure-xy measure-xy-btn', button: 'measure-xy-btn' },
      { title: 'Measure X', className: 'highcharts-measure-x measure-x-btn', button: 'measure-x-btn' },
      { title: 'Measure Y', className: 'highcharts-measure-y measure-y-btn', button: 'measure-y-btn' },
    ],
    fibonacci: [
      { title: 'Fibonacci', className: 'highcharts-fibonacci fibonacci-btn', button: 'fibonacci-btn' },
      { title: 'Fibonacci Time Zones', className: 'highcharts-fibonacci-time-zones fibonacci-time-zones-btn', button: 'fibonacci-time-zones-btn' },
      { title: 'Pitchfork', className: 'highcharts-pitchfork pitchfork-btn', button: 'pitchfork-btn' },
    ],
    channels: [
      { title: 'Parallel Channel', className: 'highcharts-parallel-channel parallel-channel-btn', button: 'parallel-channel-btn' },
      { title: 'Time Cycles', className: 'highcharts-time-cycles time-cycles-btn', button: 'time-cycles-btn' },
    ],
    verticalTools: [
      { title: 'Vertical Counter', className: 'highcharts-vertical-counter vertical-counter-btn', button: 'vertical-counter-btn' },
      { title: 'Vertical Label', className: 'highcharts-vertical-label vertical-label-btn', button: 'vertical-label-btn' },
      { title: 'Vertical Arrow', className: 'highcharts-vertical-arrow vertical-arrow-btn', button: 'vertical-arrow-btn' },
    ],
    flags: [
      { title: 'Flag Circle', className: 'highcharts-flag-circlepin flag-circlepin-btn', button: 'flag-circlepin-btn' },
      { title: 'Flag Diamond', className: 'highcharts-flag-diamondpin flag-diamondpin-btn', button: 'flag-diamondpin-btn' },
      { title: 'Flag Square', className: 'highcharts-flag-squarepin flag-squarepin-btn', button: 'flag-squarepin-btn' },
      { title: 'Flag Simple', className: 'highcharts-flag-simplepin flag-simplepin-btn', button: 'flag-simplepin-btn' },
    ],
  };

  return (
    <div className={`chart-tools highcharts-stocktools-wrapper highcharts-bindings-container highcharts-bindings-wrapper ${showTools ? '' : 'hidden'}`}>
      {Object.keys(menuItems).map((menuKey, index) => (
        <div className="hex-row" key={menuKey}>
          {getMenuContent(menuKey, menuItems[menuKey])}
          {activeMenu === menuKey && <SubMenu menu={menuKey} items={menuItems[menuKey]} />}
        </div>
      ))}
      <div className="hex-row">
        <div className={`hex-item current-price-btn-${currentPrice === false ? 'hide' : 'show active'}`} title="Current Price Indicator" onClick={toggleCurrentPrice} />
      </div>
      <div className="hex-row">
        <div className={`hex-item highcharts-toggle-annotations annotations-btn-${showAnnotations === false ? 'hide' : 'show active'}`} title="Toggle Annotations" onClick={toggleAnnotations} />
      </div>
    </div>
  );
};

export default ChartTools;