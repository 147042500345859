import React, { useState, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useData } from '../context/DataContext';
import { useUI } from '../context/UIContext'; // Importa el contexto de UI

const SelectFutureModal = () => {
  const { setLoading, setTodayData, setHistoricalData, setSymbol, futures } = useData();
  const { handleCloseModal } = useUI(); // Usa el método del contexto UI para cerrar el modal
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelect = useCallback((future) => {
    setLoading(true);
    setHistoricalData([]);
    setTodayData([]);
    setSymbol(future);
    handleCloseModal('SelectFutureModal'); // Usa handleCloseModal para cerrar el modal
  }, [setLoading, setHistoricalData, setTodayData, setSymbol, handleCloseModal]);

  const filteredFutures = futures.filter(future => future.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Modal show={true} onHide={() => handleCloseModal('SelectFutureModal')}
    dialogClassName="modal-dialog-centered">
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar Futuro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          placeholder="Buscar futuro"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {Array.isArray(filteredFutures) && filteredFutures.map((future, index) => (
          <div key={index} onClick={() => handleSelect(future)} style={{ cursor: 'pointer', padding: '5px' }}>
            {future}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleCloseModal('SelectFutureModal')}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectFutureModal;