import React, { useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';

const UserProfileModal = ({ show, handleClose }) => {
  const { user, updateUser, alertMessage } = useAuth();
  const [nombre, setNombre] = useState(user.nombre);
  const [apellido, setApellido] = useState(user.apellido);
  const [email, setEmail] = useState(user.email);
  const [avatar, setAvatar] = useState(null);
  const [error, setError] = useState('');

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await updateUser(nombre, apellido, email, avatar);
      handleClose();
    } catch (err) {
      setError(alertMessage || 'Error al actualizar el usuario');
    }
  };

  const avatarUrl = user.avatar 
    ? `https://bee-connections.com/${user.avatar}`
    : 'https://www.gravatar.com/avatar?d=mp&s=200';

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modificar Información del Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-4 avatar-container">
          <img
            src={avatarUrl}
            alt="User Avatar"
            className="profile-avatar"
            onClick={handleAvatarClick}
            title="Actualizar avatar"
          />
          <input 
            type="file" 
            ref={fileInputRef} 
            style={{ display: 'none' }} 
            accept="image/*" 
            onChange={handleFileChange} 
          />
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNombre" className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formApellido" className="mb-3">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              type="text"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Correo electrónico</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-center">
            <Button type="submit">
              Guardar Cambios
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UserProfileModal;