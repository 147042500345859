import { useEffect, useState, useCallback } from 'react';
import { filterTodayTrades } from '../utils/utils';

const useWebSocket = (token, symbol, setHistoricalData, setTodayData, setFutureData, setWsConnected, setLoading) => {
  const [ws, setWs] = useState(null);

  const connectWebSocket = useCallback(() => {
    if (!token) return;

    const wsInstance = new WebSocket('ws://localhost:4000');

    wsInstance.onopen = () => {
      console.log('WebSocket connection established');
      setWsConnected(true);
      wsInstance.send(JSON.stringify({
        action: 'subscribe',
        products: [{ symbol }]
      }));
    };

    wsInstance.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (Array.isArray(message.symbol) && message.symbol[0] === symbol) {
        setHistoricalData(message.tradeData);
        const todayTrades = filterTodayTrades(message.tradeData);
        setTodayData(todayTrades);
        setLoading(false);
      } else if (message.symbol === symbol) {
        setTodayData(message.recentTrades);
        setLoading(false);
      } else if (message.instrumentId?.symbol === symbol) {
        setFutureData([{ timestamp: message.timestamp, marketData: message.marketData }]);
      }
    };

    wsInstance.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsInstance.onclose = () => {
      setWsConnected(false);
    };

    setWs(wsInstance);

    return () => {
      if (wsInstance) {
        wsInstance.close();
      }
    };
  }, [symbol, token, setFutureData, setHistoricalData, setLoading, setTodayData, setWsConnected]);

  // useEffect(() => {
  //   const cleanup = connectWebSocket();
  //   return () => cleanup && cleanup();
  // }, [connectWebSocket]);

  return { ws };
};

export default useWebSocket;