import React, { useEffect, useState } from 'react';
import './App.css';
import Chart from './components/chart/Chart';
import Tablas from './components/tables/Tablas';
import Login from './components/auth/Login';
import Navbar from './components/navbar/Navbar';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { AuthProvider, useAuth } from './components/context/AuthContext';
import { DataProvider, useData } from './components/context/DataContext';
import { UIProvider, useUI } from './components/context/UIContext';
import { WebSocketProvider } from './components/context/WebSocketContext';
import { ThemeProvider, useTheme } from './components/context/ThemeContext';
import SelectFutureModal from './components/modals/SelectFutureModal';
import CompareFuturesModal from './components/modals/CompareFuturesModal';
import SavedChartsModal from './components/modals/SavedChartsModal';
import ChangeColorsModal from './components/modals/ChangeColorsModal'; // Importamos el nuevo modal
import Loading from './components/chart/Loading';
import ChartTools from './components/chart/ChartTools';

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <DataProvider>
            <UIProvider>
              <AppContent />
            </UIProvider>
          </DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

function AppContent() {

  const { token, user, alertMessage, setAlertMessage, handleLogout } = useAuth();
  const { symbol, setHistoricalData, setTodayData, setFutureData, setIndexData } = useData();
  const { theme } = useTheme();
  const { showSelectFutureModal, showCompareFuturesModal, showSavedChartsModal, 
    showChangeColorsModal, isFullScreen, showTools, setShowTools, showTables, toggleTables } = useUI();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://demo-live-data.highcharts.com/aapl-ohlcv.json');
      const result = await response.json();
      setData(result);
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    document.body.className = theme;

  }, [theme]);

  return (
    <WebSocketProvider
      token={token}
      symbol={symbol}
      setHistoricalData={setHistoricalData}
      setTodayData={setTodayData}
      setFutureData={setFutureData}
      setIndexData={setIndexData}
    >
      <div className={`App ${theme}`}>
        {alertMessage && (
          <Alert variant="warning" onClose={() => setAlertMessage('')} dismissible>
            {alertMessage}
          </Alert>
        )}

        {token ? (
          <>
            <Navbar user={user} handleLogout={handleLogout} />
            {loading ? (
              <Loading /> // Mostrar componente Loading si el estado loading es true
            ) : (
              <div className={`main-container ${isFullScreen ? 'full-screen' : ''}`}>

                <ChartTools />
                <div
                  className={`toggle-tools-btn ${showTools ? '' : 'hidden'}`}
                  onClick={() => setShowTools(!showTools)}
                />

                <Chart data={data} />
                <div
                  className={`toggle-tables-btn ${showTables ? '' : 'hidden'}`}
                  onClick={() => toggleTables(!showTables)}
                />
                <Tablas />
              </div>
            )}
            <Routes>
              <Route path="*" element={<Navigate to="/futuros" />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>

      {/* Renderizar todos los modales */}
      {showSelectFutureModal && <SelectFutureModal />}
      {showCompareFuturesModal && <CompareFuturesModal />}
      {showSavedChartsModal && <SavedChartsModal />}
      {showChangeColorsModal && <ChangeColorsModal />}
    </WebSocketProvider>
  );
}

export default App;