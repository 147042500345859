import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useUI } from '../context/UIContext'; // Importa el contexto de UI

const SavedChartsModal = () => {
  const { showSavedChartsModal, handleCloseModal } = useUI(); // Usa el método del contexto UI para manejar la visibilidad

  return (
    <Modal show={showSavedChartsModal} onHide={() => handleCloseModal('SavedChartsModal')}>
      <Modal.Header closeButton>
        <Modal.Title>Gráficos Guardados</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Agrega el contenido de tus gráficos guardados aquí */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleCloseModal('SavedChartsModal')}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SavedChartsModal;