import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RgbaColorPicker, HexColorPicker } from "react-colorful";

// Define convertColorToObject en un ámbito accesible por todas las funciones.
const convertColorToObject = (color) => {
  if (typeof color === 'string') {
    if (color.startsWith('rgba')) {
      const match = color.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)/);
      if (match) {
        return {
          r: parseInt(match[1], 10),
          g: parseInt(match[2], 10),
          b: parseInt(match[3], 10),
          a: parseFloat(match[4])
        };
      }
    } else if (color.startsWith('#') && color.length === 9) { // Hexadecimal con alpha (#RRGGBBAA)
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      const a = parseInt(color.slice(7, 9), 16) / 255;
      return { r, g, b, a };
    }
  } else if (typeof color === 'object' && color !== null && 'r' in color && 'g' in color && 'b' in color && 'a' in color) {
    // Si ya es un objeto rgba válido, devolverlo directamente
    return color;
  }
  return { r: 0, g: 0, b: 0, a: 1 }; // Valor por defecto si el formato no es válido
};

// Componente reutilizable para los selectores de colores
const ColorPickerField = ({ title, color, setColor, activePicker, setActivePicker, pickerType, colorFormat }) => {
  const convertedColor = colorFormat === 'rgba' ? convertColorToObject(color) : color;

  return (
    <div className="color-picker-field">
      <div
        className="color-box"
        style={{ backgroundColor: typeof color === 'string' ? color : `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` }}
        onClick={() => setActivePicker(activePicker === pickerType ? null : pickerType)}
        title={title}
      />
      {activePicker === pickerType && (
        <>
          {colorFormat === 'rgba' ? (
            <RgbaColorPicker color={convertedColor} onChange={(newColor) => setColor(`rgba(${newColor.r}, ${newColor.g}, ${newColor.b}, ${newColor.a})`)} />
          ) : (
            <HexColorPicker color={typeof color === 'string' ? color : '#000000'} onChange={setColor} />
          )}
        </>
      )}
    </div>
  );
};

const detectColorFormat = (color) => {
  if (typeof color === 'string') {
    if (color.startsWith('#')) {
      return color.length === 9 ? 'rgba' : 'hex';
    } else if (color.startsWith('rgba')) {
      return 'rgba';
    }
  }
  return 'hex'; // Default to hex if undetectable
};

const generateFibonacciColors = (baseColor) => {
  const colorObject = convertColorToObject(baseColor);
  const colors = [];

  // Generar colores ligeramente diferentes pero consistentes
  for (let i = 0; i < 6; i++) {
    const newColor = {
      r: Math.min((colorObject.r + i * 20), 255), // Incrementos suaves en el rojo
      g: Math.min((colorObject.g + i * 20), 255), // Incrementos suaves en el verde
      b: Math.min((colorObject.b + i * 20), 255), // Incrementos suaves en el azul
      a: colorObject.a
    };
    colors.push(`rgba(${newColor.r}, ${newColor.g}, ${newColor.b}, ${newColor.a})`);
  }

  return colors;
};


const PopUp = React.memo(({ annotation }) => {
  const [activePicker, setActivePicker] = useState(null);
  const pickerRef = useRef(null);

  const annotationConfig = {
    basicAnnotation: [
      { field: 'backgroundColor', title: 'Color de fondo' },
      { field: 'borderColor', title: 'Color del borde' },
      { field: 'textColor', title: 'Color del texto', condition: (annotation) => annotation?.options?.langKey === 'label' },
      { field: 'fontSize', title: 'Tamaño de la fuente', type: 'number', condition: (annotation) => annotation?.options?.langKey === 'label' }
    ],
    elliottWave: [
      { field: 'lineColor', title: 'Color de la línea' },
      { field: 'lineSize', title: 'Grosor de la línea', type: 'number' },
      { field: 'elliottWaveColor', title: 'Color del Elliott Wave' },
      { field: 'elliottWaveSize', title: 'Tamaño del Elliott Wave', type: 'number' }
    ],
    measure: [
      { field: 'measureBackground', title: 'Color de fondo' },
      { field: 'measureText', title: 'Color del texto' },
      { field: 'measureWidth', title: 'Grosor del texto', type: 'number' }
    ],
    fibonacci: [
      { field: 'fibonacciBackgrounds', title: 'Colores de fondo' },
      { field: 'fibonacciLines', title: 'Color de las líneas' },
      { field: 'fibonacciLabels', title: 'Color de las etiquetas' },
      { field: 'fibonacciLabelsSize', title: 'Tamaño de las etiquetas', type: 'number' }
    ],
    fibonacciTimeZones: [
      { field: 'fibonacciTimeZonesColor', title: 'Color de las lineas' },
      { field: 'fibonacciTimeZonesSize', title: 'Tamaño de las lineas', type: 'number' },
    ],
    pitchfork: [
      { field: 'pitchforkInner', title: 'Color del interior' },
      { field: 'pitchforkOuter', title: 'Color del exterior' },
      { field: 'pitchforkLines', title: 'Color de las líneas' }
    ],
    tunnel: [
      { field: 'tunnelBackground', title: 'Color de fondo' },
      { field: 'tunnelLines', title: 'Color de las líneas' }
    ],
    timeCycles: [
      { field: 'lineColor', title: 'Color de la línea' },
      { field: 'lineSize', title: 'Grosor de la línea', type: 'number' },
    ],
    verticalLine: [
      { field: 'verticalConnector', title: 'Color del conector' },
      { field: 'verticalLabel', title: 'Color de la etiqueta' }
    ],
    flags: [
      { field: 'flagTitle', title: 'Texto del flag', type: 'text' },
      { field: 'flagName', title: 'Nombre del flag', type: 'text' }
    ],
    infinityLine: [
      { field: 'lineColor', title: 'Color de la línea' },
      { field: 'lineSize', title: 'Grosor de la línea', type: 'number' }
    ],
    crookedLine: [
      { field: 'lineColor', title: 'Color de la línea' },
      { field: 'lineSize', title: 'Grosor de la línea', type: 'number' }
    ]
  };

  const getInitialColorStates = useCallback(() => {

    if (!annotation) return {};

    console.log('annotation', annotation);

    switch (annotation.options.type) {
      case 'basicAnnotation':
        return {
          textColor: annotation?.options?.labelOptions?.style?.color === 'contrast' ? 'white' : annotation?.options?.labelOptions?.style?.color || '#000000',
          backgroundColor: typeof annotation?.options?.labelOptions?.backgroundColor === 'string' ? annotation?.options?.labelOptions?.backgroundColor : annotation?.options?.shapes?.[0]?.fill || '#ffffff',
          borderColor: typeof annotation?.options?.labelOptions?.borderColor === 'string' ? annotation?.options?.labelOptions?.borderColor : annotation?.options?.shapes?.[0]?.stroke || 'rgba(0, 0, 0, 1)',
          fontSize: parseInt(annotation?.options?.labelOptions?.style?.fontSize) || 11
        };
      case 'elliottWave':
        return {
          elliottWaveColor: annotation?.labels?.[0]?.options?.style?.color || '#000000',
          elliottWaveSize: parseInt(annotation?.labels?.[0]?.options?.style?.fontSize) || 10,
          lineColor: annotation?.options?.typeOptions?.line?.stroke || '#000000',
          lineSize: annotation?.options?.typeOptions?.line?.strokeWidth || 1
        };
      case 'measure':
        return {
          measureText: annotation?.options?.typeOptions?.label?.style?.color || '#000000',
          measureWidth: parseInt(annotation?.options?.typeOptions?.label?.style?.fontSize) || 1,
          measureBackground: annotation?.options?.typeOptions?.background?.fill || 'rgba(255, 255, 255, 1)',
        };
      case 'fibonacci':
        return {
          fibonacciBackgrounds: annotation?.options?.typeOptions?.backgroundColors[0] || [annotation?.options?.typeOptions?.background?.fill],
          fibonacciLabels: annotation?.options?.typeOptions?.labels?.[0]?.style?.color || '#000000',
          fibonacciLabelsSize: parseInt(annotation?.options?.typeOptions?.labels?.[0]?.style?.fontSize) || 10,
          fibonacciLines: annotation?.options?.typeOptions?.lineColor || 'rgba(0, 0, 0, 1)',
        };
      case 'fibonacciTimeZones':
        return {
          fibonacciTimeZonesColor: annotation?.options?.typeOptions?.line?.stroke || 'rgba(0, 0, 0, 0.75)',
          fibonacciTimeZonesSize: annotation?.options?.typeOptions?.line?.strokeWidth || 1,
        };
      case 'flags':
        return {
          flagTitle: annotation?.options?.title?.[0] || '',
          flagName: annotation?.options?.name?.[0] || '',
        };
      case 'pitchfork':
        return {
          pitchforkInner: annotation?.options?.typeOptions?.innerBackground?.fill || 'rgba(0, 0, 0, 1)',
          pitchforkOuter: annotation?.options?.typeOptions?.outerBackground?.fill || 'rgba(255, 255, 255, 1)',
          pitchforkLines: annotation?.options?.shapeOptions?.stroke || '#000000',
        };
      case 'tunnel':
        return {
          tunnelBackground: annotation?.options?.typeOptions?.background?.fill || 'rgba(0, 0, 0, 1)',
          tunnelLines: annotation?.options?.typeOptions?.line?.stroke || 'rgba(255, 255, 255, 1)',
        };
      case 'verticalLine':
        return {
          verticalConnector: annotation?.options?.typeOptions?.connector?.stroke || 'rgba(0, 0, 0, 1)',
          verticalLabel: annotation?.options?.typeOptions?.label?.style?.color || '#000000',
        };
      case 'timeCycles':
      case 'infinityLine':
      case 'crookedLine':
        return {
          lineColor: annotation?.options?.typeOptions?.line?.stroke || '#000000',
          lineSize: annotation?.options?.typeOptions?.line?.strokeWidth || 1,
        };
      default:
        return {};
    }
  }, [annotation]);

  const [colors, setColors] = useState(getInitialColorStates);

  useEffect(() => {
    if (annotation) {
      setColors(getInitialColorStates());
    } else {
      setActivePicker(null);
    }
  }, [annotation, getInitialColorStates]);

  const updateAnnotation = useCallback((field, value) => {
    setColors(prev => ({ ...prev, [field]: value }));

    if (!annotation) return;
    const updateConfig = {

      textColor: () => annotation.update({
        labelOptions: {
          style: {
            color: value
          },
        },
      }),
      backgroundColor: () => annotation.update({
        labelOptions: {
          backgroundColor: value,
        },
        shapes: annotation.options.shapes?.map(s => ({
          fill: value,
        })),
      }),
      borderColor: () => annotation.update({
        labelOptions: {
          borderColor: value,
        },
        shapes: annotation.options.shapes?.map(s => ({
          stroke: value,
        })),
      }),
      fontSize: () => annotation.update({
        labelOptions: {
          style: {
            fontSize: `${value}px`,
          },
        },
      }),
      fibonacciBackgrounds: () => annotation.update({
        typeOptions: {
          backgroundColors: generateFibonacciColors(value),
        },
      }),
      fibonacciLabels: () =>
        annotation.labels.forEach(label => {
          label.update({
            style: {
              color: value
            },
          });
        }),
      fibonacciLabelsSize: () =>
        annotation.labels.forEach(label => {
          label.update({
            style: {
              fontSize: value
            },
          });
        }),
      fibonacciLines: () => annotation.update({
        typeOptions: {
          lineColor: value
        }
      }),
      fibonacciTimeZonesColor: () => annotation.update({
        typeOptions: {
          line: {
            stroke: value
          }
        }
      }),
      fibonacciTimeZonesSize: () => annotation.update({
        typeOptions: {
          line: {
            strokeWidth: value
          }
        }
      }),
      elliottWaveColor: () => {
        annotation.labels.forEach(label => {
          label.update({
            style: {
              color: value
            },
          });
        });
      },
      elliottWaveSize: () => {
        annotation.labels.forEach(label => {
          label.update({
            style: {
              fontSize: value
            },
          });
        });
      },
      measureText: () => annotation.update({
        typeOptions: {
          label: {
            style: {
              color: value,
            },
          },
        },
      }),
      measureWidth: () => annotation.update({
        typeOptions: {
          label: {
            style: {
              fontSize: `${value}px`,
            },
          },
        },
      }),
      measureBackground: () => annotation.update({
        typeOptions: {
          background: {
            fill: value,
          },
        },
      }),
      pitchforkInner: () => annotation.update({
        typeOptions: {
          innerBackground: {
            fill: value,
          },
        },
      }),
      pitchforkOuter: () => annotation.update({
        typeOptions: {
          outerBackground: {
            fill: value,
          },
        },
      }),
      pitchforkLines: () => annotation.update({
        shapeOptions: {
          stroke: value
        },
      }),
      tunnelBackground: () => annotation.update({
        typeOptions: {
          background: {
            fill: value,
          },
        },
      }),
      tunnelLines: () => annotation.update({
        typeOptions: {
          line: {
            stroke: value,
          },
        },
      }),
      verticalConnector: () => annotation.update({
        typeOptions: {
          connector: {
            stroke: value,
            fill: 'none'
          },
        },
      }),
      verticalLabel: () => annotation.update({
        typeOptions: {
          label: {
            style: {
              color: value,
            },
          },
        },
      }),
      lineColor: () => annotation.update({
        typeOptions: {
          line: {
            stroke: value,
          },
        }
      }),
      lineSize: () => annotation.update({
        typeOptions: {
          line: {
            strokeWidth: value,
          },
        }
      }),
      flagTitle: () => annotation.point.update({
        title: value
      }),
      flagName: () => annotation.point.update({
        name: value
      })
    };

    if (updateConfig[field]) {
      updateConfig[field]();
    } else {
      console.warn('Unknown field in annotation update:', field);
    }
  }, [annotation]);

  const handleInputChange = (field, value) => {
    updateAnnotation(field, value);
  };

  const handleRemoveAnnotation = () => {
    if (annotation.options.type === 'flags') {
      annotation.point.remove();
    } else {
      annotation.remove();
    }
    const popup = document.getElementById('annotation-popup');
    if (popup) {
      popup.style.display = 'none';
    }
    setActivePicker(null);
  };

  const renderColorPickersForAnnotation = (annotationType) => {
    return annotationConfig[annotationType]?.map(({ field, title, type, condition }) => {
      if (condition && !condition(annotation)) return null;
      if (type === 'number') {
        return (
          <div key={field} className="font-field">
            <input
              type="number"
              value={colors[field] || 16}
              onChange={e => handleInputChange(field, e.target.value)}
              placeholder={title}
            />
          </div>
        );
      } else if (type === 'text') {
        return (
          <div key={field} className="font-field">
            <input
              type="text"
              value={colors[field] || ''}
              onChange={e => handleInputChange(field, e.target.value)}
              placeholder={title}
            />
          </div>
        );
      } else {
        const colorFormat = detectColorFormat(colors[field] || '');
        return (
          <ColorPickerField
            key={field}
            color={colors[field] || '#000000'}
            setColor={(newColor) => handleInputChange(field, newColor)}
            activePicker={activePicker}
            setActivePicker={setActivePicker}
            pickerType={field}
            colorFormat={colorFormat}
            title={title}
          />
        );
      }
    });
  };

  return (
    <div id="annotation-popup" className="annotation-popup">
      <div className="popup-title">{annotation?.options?.langKey || 'Sin anotación'}</div>
      {renderColorPickersForAnnotation(annotation?.options?.type)}
      <div className="popup-close" onClick={handleRemoveAnnotation} />
    </div>
  );
});

export default PopUp;