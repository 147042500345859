import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import DragPanes from 'highcharts/modules/drag-panes';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import PriceIndicator from 'highcharts/modules/price-indicator';
import IndicatorsAll from 'highcharts/indicators/indicators-all';
import StockTools from 'highcharts/modules/stock-tools';
import HeikinAshi from 'highcharts/modules/heikinashi';
import HollowCandlestick from 'highcharts/modules/hollowcandlestick';
import Accessibility from 'highcharts/modules/accessibility';
import Boost from 'highcharts/modules/boost';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import { useUI } from '../context/UIContext';
import PopUp from './PopUp';
import 'highcharts/css/annotations/popup.css';
import './chart.css'
import { setChartInstance } from '../../utils/chartUtils';
import ChartHeader from './ChartHeader';

DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
IndicatorsAll(Highcharts);
StockTools(Highcharts);
HeikinAshi(Highcharts);
HollowCandlestick(Highcharts);
Accessibility(Highcharts);
Boost(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);


const getComputedHexColor = (cssVariable) => {
  const tempElement = document.createElement('div');
  document.body.appendChild(tempElement);
  tempElement.style.display = 'none';
  tempElement.style.color = cssVariable;
  const computedColor = window.getComputedStyle(tempElement).color;
  document.body.removeChild(tempElement);
  return rgbToHex(computedColor);
};

const rgbToHex = (rgb) => {
  const result = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  if (!result) {
    return null;
  }
  const r = parseInt(result[1]).toString(16).padStart(2, '0');
  const g = parseInt(result[2]).toString(16).padStart(2, '0');
  const b = parseInt(result[3]).toString(16).padStart(2, '0');

  // Si hay un valor de opacidad (alpha), procesamos el valor.
  if (result[4]) {
    const alpha = Math.round(parseFloat(result[4]) * 255).toString(16).padStart(2, '0');
    return `#${r}${g}${b}${alpha}`;
  }

  return `#${r}${g}${b}`;
};
const Chart = ({ data }) => {
  const chartRef = useRef(null);

  const { setActiveButton, isFullScreen, showTools, setShowTools, showTables, toggleTables, setMainSeriesType } = useUI();
  const [annotation, setAnnotation] = useState(null);
  const [seriesList, setSeriesList] = useState([]);

  useEffect(() => {
    const loadChart = async () => {

      const ohlc = data.map(item => [item[0], item[1], item[2], item[3], item[4]]);
      const volume = data.map(item => [item[0], item[5]]);

      if (!chartRef.current) {

        const backgroundColor = getComputedHexColor('var(--chart-bg)');
        const textColor = getComputedHexColor('var(--chart-text)');
        const linesColor = getComputedHexColor('var(--chart-lines)');
        const upColor = getComputedHexColor('var(--chart-up)');
        const downColor = getComputedHexColor('var(--chart-down)');
        const volumeColor = getComputedHexColor('var(--chart-volume)');
        const btnColor = getComputedHexColor('var(--chart-btn)');
        const btnTextColor = getComputedHexColor('var(--chart-btn-text)');
        const btnColorHover = getComputedHexColor('var(--chart-btn-hover)');
        const btnTextColorHover = getComputedHexColor('var(--chart-btn-text-hover)');
        const annotationColor = getComputedHexColor('var(--chart-annotation)');
        const beeYellow = getComputedHexColor('var(--bee-yellow)');

        chartRef.current = Highcharts.stockChart('container', {

          chart: {
            backgroundColor: backgroundColor,
            events: {
              load: function () {
                setSeriesList(this.series.map(s => ({
                  id: s.options.id,
                  name: s.name,
                  visible: s.visible
                })));
              },
              addSeries: function (event) {
                console.log('Serie agregada', event);
                if (event.options.type === 'flags') {
                  setActiveButton(null);
                } else {
                  setSeriesList(prev => [
                    ...prev,
                    {
                      id: event.options.id,
                      name: event.options.name,
                      visible: event.options.visible !== false
                    }
                  ]);
                }
              },
              removeSeries: function (event) {
                setSeriesList(prev => prev.filter(s => s.id !== event.target.options.id));
              }
            }
          },
          yAxis: [
            {
              labels: { align: 'left', style: { color: textColor } },
              gridLineColor: linesColor,
              height: '100%',
              resize: { enabled: false },
              type: 'linear',
               
            },
            {
              labels: { enabled: false },
              gridLineWidth: 0,
              top: '85%',
              height: '15%',
            },
          ],
          xAxis: { labels: { style: { color: textColor } } },
          series: [
            {
              type: 'candlestick',
              id: 'price',
              name: 'AAPL Stock Price',
              data: ohlc,
              upColor: upColor,
              upLineColor: upColor,
              color: downColor,
              lineColor: downColor,
              yAxis: 0
            },
            {
              type: 'column',
              id: 'volume',
              name: 'AAPL Volume',
              data: volume,
              yAxis: 1,
              color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, volumeColor],
                  [1, '#fff']
                ]
              }
            }
          ],
          stockTools: { gui: { enabled: false } },
          navigator: { enabled: true },
          scrollbar: { enabled: false },
          tooltip: {
            shared: true, // Comparte un solo tooltip para ambas series
            useHTML: true, // Permite HTML personalizado
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo semitransparente
            borderColor: 'transparent', // Borde transparente para un estilo limpio
            style: {
              color: '#fff', // Texto blanco para buen contraste
              fontSize: '13px'
            },
          },
          exporting: { buttons: { contextButton: { verticalAlign: 'bottom' } }, chartOptions: { chart: { width: 1920, height: 1080 } } },
          rangeSelector: {
            verticalAlign: 'bottom',
            buttonTheme: {
              fill: btnColor,
              style: {
                color: textColor,
              },
              states: {
                hover: {
                  fill: btnColorHover,
                  style: {
                    color: btnTextColorHover,
                  },
                },
                select: {
                  fill: beeYellow,
                  style: {
                    color: btnTextColor,
                  },
                },
              },
            },
            labelStyle: {
              color: textColor,
            },
            inputStyle: {
              color: textColor,
            }
          },
          navigation: {
            annotationsOptions: {
              events: {
                add: function (e) {
                  setActiveButton(null);
                  console.log('Nueva anotación añadida', e);
                },
              },
              shapeOptions: {
                fill: annotationColor,
                stroke: annotationColor,
              },
              typeOptions: {
                connector: {
                  stroke: annotationColor,
                }
              },
            },
            events: {
              showPopup: function (e) {

                console.log(e);
                
                // Verifica si se está mostrando el popup de anotación
                if (e.formType === 'annotation-toolbar' || e.formType === 'flag') {
                  const highchartsPopup = document.querySelector('.highcharts-popup');
                  setAnnotation(e.annotation || e);
                  if (highchartsPopup) {
                    highchartsPopup.style.display = 'none';
                  }

                  const popup = document.getElementById('annotation-popup');
                  if (popup) {
                    popup.style.display = 'flex';
                  }
                  // Si se muestra el popup de indicadores, cierra el popup de anotaciones
                } else {
                  const popup = document.getElementById('annotation-popup');
                  if (popup) {
                    popup.style.display = 'none';
                  }
                  setAnnotation(null);
                }

              },

              closePopup: function () {
                const popup = document.getElementById('annotation-popup');
                if (popup) {
                  popup.style.display = 'none';
                }
              }
            }
          }
        });
        setChartInstance(chartRef.current);
        addEventListeners(chartRef.current);
      }
    };

    loadChart();
  }, []);


  // Definir eventos una vez que el gráfico está disponible
  const addEventListeners = (chart) => {

    setMainSeriesType(chart.series[0].type);

    if (!chart.hasAddedEvents) {
      chart.hasAddedEvents = true;

      // Evento para ocultar/mostrar el indicador de precio actual
      document.querySelector('.current-price-btn-hide').addEventListener('click', function () {
        const series = chart.series[0];
        const isPriceIndicatorEnabled = series.options.lastPrice?.enabled;

        series.update({
          lastPrice: {
            enabled: !isPriceIndicatorEnabled,
            color: 'red'
          },
          lastVisiblePrice: {
            enabled: !isPriceIndicatorEnabled,
            label: {
              enabled: !isPriceIndicatorEnabled,
              style: {
                color: 'white',
                fontWeight: 'bold'
              },
              backgroundColor: 'black'
            }
          }
        });
      });

      // Mejorar donde aparece el popup y sus comportamientos
      Highcharts.addEvent(chart.container, 'click', function (event) {
        var popup = document.getElementById('annotation-popup');

        if (popup) {
          // Obtener las dimensiones del pop-up
          var popupWidth = popup.offsetWidth;

          // Calcula la posición del pop-up basada en la ubicación del clic y el ancho del contenedor
          var leftPos = event.pageX + 20; // Posición inicial a la derecha del clic
          var rightEdge = chart.container.getBoundingClientRect().right; // Borde derecho del contenedor del gráfico

          // Comprueba si el popup se desborda del contenedor del gráfico, y ajusta si es necesario
          if (leftPos + popupWidth > rightEdge) {
            leftPos = event.pageX - popupWidth - 20; // Posición a la izquierda del clic si no hay espacio a la derecha
          }

          popup.style.left = leftPos + 'px';
          popup.style.top = `${event.pageY - popup.offsetHeight / 2}px`;
        } else {
          console.error('No se encontró el elemento con id "annotation-popup".');
        }
      });
    }
  };



  const handleVisibilityToggle = (id) => {
    const chart = chartRef.current;
    const series = chart.get(id);
    if (series) {
      series.setVisible(!series.visible, false);
      setSeriesList(prev =>
        prev.map(s => s.id === id ? { ...s, visible: !s.visible } : s)
      );
    }
  };

  const handleRemoveSeries = (id) => {
    const chart = chartRef.current;
    const series = chart.get(id);
    if (series) {
      series.remove();
    }
  };

  return (
    <div className='chart-container'>
      <ChartHeader />
      <div className="series-list">
        {seriesList.map(series => (
          <div key={series.id} className="series-item">
            <span>{series.name}</span>
            <button onClick={() => handleVisibilityToggle(series.id)}>
              {series.visible ? 'Ocultar' : 'Mostrar'}
            </button>
            <button onClick={() => handleRemoveSeries(series.id)}>Eliminar</button>
          </div>
        ))}
      </div>
      <div
        id="container"
        style={{
          width: '100%',
          height: isFullScreen ? '100vh' : '99%',
          margin: isFullScreen ? '0' : 'auto',
        }}
      />
      <PopUp annotation={annotation} />
    </div>
  );
};

export default Chart;